<template>
  <div>
    <div class="col-md-12 mt-4 containerHeader">
      <div class="row">
        <div class="col-3">
          <h2>Serviço</h2>
        </div>
        <div class="col-2 text-center">
          <h2>Quantidade</h2>
        </div>
        <div class="col-2 text-center">
          <h2>Valor Unitário (R$)</h2>
        </div>
        <div class="col-3 text-center">
          <h2>Valor Promocional (R$)</h2>
        </div>
        <div class="col-2">
          <h2>Subtotal (R$)</h2>
        </div>
      </div>
    </div>
    <div class="containerServices" v-if="itemRequest.servicesOrder">
      <containerServico
        v-for="(item, index) in itemRequest.servicesOrder"
        :key="index"
        :item="item"
        :quantityItem="itemRequest.servicesOrder.length"
      />
    </div>
    <div
      class="col-12 containerPay d-flex align-items-center justify-content-between"
    >
      <button class="btnDefault" @click="search">
        <img src="@/assets/image/pedidos/add.png" alt="add" />
        Add Serviço
      </button>
      <valueAll :price="itemRequest.total" />
    </div>
    <modalDegustacao/>
  </div>
</template>

<script>
import containerServico from "./container_servico";
import modalDegustacao from "@/components/modal/modalDegustacao";
import valueAll from "./ValueAll";
export default {
  props: {
    itemRequest: {
      type: Object,
      default: {}
    }
  },
  components: {
    containerServico,
    modalDegustacao,
    valueAll
  },
  methods: {
    search() {
      if (this.$store.getters.get_tenant.type == 4 && this.$store.getters.getUser.profile != 1) {
        $("#modalDegustacao").modal("show");
      } else {
        $(".input_search_list input").click();
      }
    }
  }
};
</script>

<style scoped>
* {
  font-style: normal;
  font-weight: 500;
}
.containerHeader h2 {
  font-size: 1rem;
  font-weight: 500;
  color: #ffffff;
}
.containerPay {
  margin-top: 20px;
  background: #206aad;
  border-radius: 8px;
  height: 65px;
}
.btnDefault {
  background: #f38235;
  border-radius: 6px;
  border: none;
  color: #fff;
  height: 40px;
  width: 162px;
  padding: 0 20px;
  border: none;
  transition: all 0.2s linear;
  outline: none;
}
.btnDefault:hover {
  background-color: #e0a638;
}

.containerPay strong {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
}

.containerPay h3 {
  color: #98c4e6;
  font-weight: 700;
  font-size: 20px;
  margin: 0;
}
</style>
