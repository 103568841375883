<template>
  <div>
    <div class="container containerService">
      <div class="row h-100">
        <div class="col-3 d-flex align-items-center">
          <h2 class="m-0">
            {{ item.serviceName }}
          </h2>

          <div
            v-if="
              item.dataDispatcher != '' &&
                item.dataDispatcher != null &&
                item.dataDispatcher != '[]'
            "
            class="col-md-1 form-group form-outline"
            id="tooltip"
          >
            <i
              class="fas fa-info-circle mt-3"
              data-toggle="tooltip"
              data-placement="right"
              :title="`${convertDetails(item.dataDispatcher)}`"
            >
            </i>
          </div>
        </div>

        <div class="col-2 d-flex justify-content-center align-items-center">
          <div
            v-if="!item.hasPrint"
            class="btnChange d-flex justify-content-between align-items-center"
          >
            <button
              @click="removeQuanty"
              :class="item.quantityItens < 2 ? 'disabled' : ''"
            >
              <img src="@/assets/image/pedidos/rowPath.svg" alt="rowPath" />
            </button>
            <strong>{{
              item.quantityItens > 9
                ? item.quantityItens
                : `0${item.quantityItens}`
            }}</strong>
            <button class="addService my-2" @click="addQuanty">
              <img src="@/assets/image/pedidos/rowPath.svg" alt="rowPath" />
              <img
                class="rotate"
                src="@/assets/image/pedidos/rowPath.svg"
                alt="rowPath"
              />
            </button>
          </div>
          <div
            v-else
            class="btnChange hasPrint d-flex justify-content-center align-items-center"
          >
            <strong>{{
              item.quantityItens > 9
                ? item.quantityItens
                : `0${item.quantityItens}`
            }}</strong>
          </div>
        </div>
        <div
          class="col-2 d-flex justify-content-center align-items-center strongValue"
        >
          <strong> {{ item.value }} </strong>
        </div>
        <div
          class="col-3 d-flex justify-content-center align-items-center strongValue"
        >
          <strong> {{ item.quantityItens > 1 && item.secondValue != "R$ 0,00" && item.value != item.secondValue ? `* ${item.secondValue}` : "-" }} </strong>
        </div>
        <div
          class="col-1 d-flex justify-content-center align-items-center strongValue"
        >
          <h3>{{ valueConvert(item.subTotal) }}</h3>
        </div>
        <div
          class="col-1 d-flex justify-content-center align-items-center"
          v-if="!load"
        >
          <button
            class="comfirmService mr-1"
            v-if="comfirm"
            @click="comfirmService"
          >
            <img
              src="@/assets/image/pedidos/finalizar.png"
              alt="deleteService"
            />
          </button>
          <button
            v-if="quantityItem > 1"
            class="deleteService"
            @click="deleteService"
          >
            <img
              src="@/assets/image/pedidos/deleteService.png"
              alt="deleteService"
            />
          </button>
        </div>
        <div
          class="col-1 d-flex justify-content-center align-items-center"
          v-else
        >
          <half-circle-spinner
            :animation-duration="1000"
            :size="30"
            color="#fff"
          />
        </div>
      </div>
    </div>
    <div v-if="item.quantityItens > 1 && item.secondValue != 'R$ 0,00' && item.value != item.secondValue" class="second-value-message">
      <p>* Valor promocional a partir da segunda unidade.</p>
    </div>
  </div>
</template>

<script>
import { HalfCircleSpinner } from "epic-spinners";
import HTTP from "@/api_system";
import { mapMutations, mapGetters } from "vuex";

export default {
  components: {
    HalfCircleSpinner
  },
  props: {
    item: {
      type: Object,
      default: {}
    },
    quantityItem: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      comfirm: false,
      load: false,
      beforeChange: undefined
    };
  },
  computed: {
    ...mapGetters(["getNewPedidos"])
  },
  methods: {
    ...mapMutations(["newDiscard"]),
    addQuanty() {
      if (!this.beforeChange) this.beforeChange = this.item.quantityItens;
      this.item.quantityItens = this.item.quantityItens + 1;

      if (!this.comfirm) this.comfirm = true;
    },
    removeQuanty() {
      if (!this.beforeChange) this.beforeChange = this.item.quantityItens;
      if (this.item.quantityItens < 2) {
        this.toast("Quantidade não pode ser menor que 1", this.$toast.error);
        return;
      }
      this.item.quantityItens = this.item.quantityItens - 1;
      if (!this.comfirm) this.comfirm = true;
    },
    async comfirmService() {
      this.load = true;
      const { orderServiceId, quantityItens } = this.item;
      const paramsData = {
        orderServiceId: orderServiceId,
        listPrint: [],
        quantity: quantityItens
      };

      try {
        const { data } = await HTTP.post(
          `services/app/OrdersServices/ApplyOrdersService`,
          paramsData,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getterToken}`
            }
          }
        );
        const { result } = data;

        console.log(result);
        this.$store.commit("set_pedido", result);
        this.load = false;
        this.comfirm = false;
      } catch (error) {
        console.error(error);
        this.load = false;
        this.toast(error.response.data.error.message, this.$toast.error);
        if (error.response.data.error.code === 302) {
          this.redirectPageError();
          return;
        }

        if (error.response.data.error.code === 100) {
          this.redirectPageError();
          return;
        }
      }
    },
    redirectPageError() {
      this.$store.commit("changeOrderId", "");
      setTimeout(() => {
        this.$store.commit("set_pedido", "");
        this.$router.push("/pedidos");
      }, 2000);
    },
    deleteService() {
      const json = {
        title: "Deseja descartar este serviço?",
        id: this.item.orderServiceId,
        service: true,
        url: `/services/app/OrdersServices/DiscardOrderServices?orderServiceId=${this.item.orderServiceId}&MessageCancelation`
      };
      this.newDiscard(json);
      setTimeout(() => {
        $("#modal_discard").modal("show");
      }, 50);
    },
    enableTooltip() {
      $(function() {
        $('[data-toggle="tooltip"]').tooltip();
      });
    },
    convertDetails(param) {
      const objResult = JSON.parse(param);
      let result = "\n";
      objResult.forEach(element => {
        result = `${result}•${element}\n`;
      });
      return result;
    }
  },
  mounted() {
    this.enableTooltip();
  }
};
</script>

<style scoped>
.containerService {
  background: #3f88c1;
  height: 60px;
  border: 1px solid #2474b2;
  border-radius: 8px;
}

.containerService h2,
.containerService .strongValue strong {
  font-size: 16px;
  color: #ffffff;
  font-style: normal;
  font-weight: 300;
}

.containerService .strongValue h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  color: #ffffff;
}

.containerService .btnChange {
  background: #5397cc;
  border-radius: 4px;
  height: 40px;
  width: 148px;
  margin: auto 0;
}

.hasPrint {
  background: none !important;
}

.desabled {
  cursor: not-allowed;
}
.containerService .btnChange button {
  /* height: 90%; */
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
.containerService .btnChange button:hover {
  background: #1b5fa898;
}

.containerService .comfirmService,
.containerService .deleteService {
  /* height: 90%; */
  margin: auto 0;
  border-radius: 7px;
  transition: 0.1s ease-in-out;
}
.containerService .deleteService:hover {
  background: #f65757;
}
.containerService .comfirmService {
  background: #25d366;
}

.containerService .btnChange strong {
  font-weight: 400;
  font-size: 22px;
  color: #ffffff;
}

.containerService .addService {
  position: relative;
}

.containerService .addService .rotate {
  position: absolute;
  transform: rotate(270deg);
  top: 48%;
  left: 27%;
  transform: translate(-50% -50%);
}

.containerService button {
  border: none;
  background: none;
  /* height: 100%; */
  padding: 11px;
  outline: none;
}
.second-value-message {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  margin-top: 10px;

}
.second-value-message p {
  color: white;
  font-size: 16px;
  margin-bottom: 0;
}
</style>
